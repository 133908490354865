<template>
  <div class="invoiceorder">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>地址</div>
    </div>
    <van-radio-group v-model="result" ref="checkboxGroup" @change="handleChange">
      <van-cell-group>
        <div class="content_item" v-for="item in List" :key="item.address_id">
          <div class="content_left">
            <van-radio
              :name="item.address_id"
              shape="square"
              v-model="checked"
              checked-color="#00B100"
              icon-size="16"
            >
              <!-- <template #icon="props">
                <img class="img-icon" :src="props.checked ? checked : no_checked" />
              </template>-->
            </van-radio>
          </div>
          <div class="content_right">
            <div class="item_center">
              <div class="item">
                <div class="item_left">收货人：</div>
                <div>{{item.consignee}}</div>
              </div>
              <div class="item">
                <div class="item_left">电话：</div>
                <div>{{item.mobile}}</div>
              </div>
              <div class="item">
                <div class="item_left">收货地址：</div>
                <div>{{item.address}}</div>
              </div>
              <div class="item">
                <div class="item_left">备注：</div>
                <div>{{item.note}}</div>
              </div>
              <div v-if="item.is_default=='1'" class="item">
                <div class="item_left">是否默认：</div>
                <div class="right_complete">
                  <span>是</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-cell-group>
    </van-radio-group>
    <div v-show="set_default" class="footer">
      <div class="no_all" @click="toggleAll">取消</div>
      <div class="confirm btn" @click="handleConfirm">设为默认</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: 0,
      no_checked: require("../../assets/image/no_checked.svg"),
      checked: require("../../assets/image/checked.svg"),
      List: [],
      items:[],
      set_default: false,
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    handleChange() {
      console.log(this.result, "result");
      if (this.result != 0) {
        this.set_default = true;
      } else {
        this.set_default = false;
      }
    },

    toggleAll() {
      this.result = 0;
    },
    
    async handleConfirm() {
      const data = await this.$api.setDefaultAddress({
        address_id: this.result
      });
      if (data.data.code === 1) {
        this.$notify({ type: "success", message: data.data.msg });
        setTimeout(() => {
          this.$router.go(0);
        }, 1000);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
      
    },
    async handleGetUserAddress() {
      const data = await this.$api.handleGetUserAddress();
      if (data.data.code === 1) {
        this.List = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    }
  },
  mounted() {
    this.handleGetUserAddress();
  }
};
</script>
<style lang="scss" scoped>
.invoiceorder {
  width: 375px;
  height: 100%;
  min-height: 100vh;
  background: #f5f5f5;
  padding-top: 40px;
  padding-bottom: 50px;
  box-sizing: border-box;
  .content_item {
    width: 375px;
    height: 200px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    display: flex;
    // align-items: center;
    .content_left {
      width: 40px;
      padding-left: 12px;
      padding-top: 15px;
      box-sizing: border-box;
      // img {
      //   width: 16px;
      //   height: 16px;
      // }
    }
    .content_right {
      width: 320px;
      .item_title {
        width: 320px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        line-height: 16px;
        padding: 0 8px;
        box-sizing: border-box;
        border-bottom: 1px solid #e5e5e5;
      }
      .item_center {
        width: 320px;
        margin: 0 auto;
        height: 158px;
        font-size: 14px;
        line-height: 15px;
        padding: 0 8px;
        padding-top: 15px;
        color: #333;
        box-sizing: border-box;
        .item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 21px;
          .item_left {
            color: rgba(102, 102, 102, 1);
          }
          .item_right {
            line-height: 14px;

            .item_right_price {
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: rgba(254, 29, 29, 1);
              margin-right: 16px;
            }
            .item_right_num {
              color: #666;
            }
          }
          .complete_status {
            color: rgba(153, 153, 153, 1);
          }
          .right_complete {
            color: rgba(50, 125, 251, 1);
            .complete_number {
              margin-right: 20px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .footer {
    width: 375px;
    height: 50px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 15px;
    .all {
      width: 60px;
      height: 30px;
      color: rgba(0, 177, 0, 1);
      line-height: 30px;
      text-align: left;
    }
    .no_all {
      width: 80px;
      height: 30px;
      color: #999;
      line-height: 30px;
      text-align: left;
    }
    .confirm {
      width: 100px;
      height: 30px;
      border-radius: 23px;
      line-height: 30px;
      text-align: center;
      margin-left: 130px;
      box-sizing: border-box;
    }
  }
}
</style>
